.homepage-container{
  min-height: 100vh;
  min-width: 100%;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


@import url('https://fonts.googleapis.com/css2?family=Francois+One&display=swap');
*{
  font-family: "Libre Franklin", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.nav-container{
    background-color: rgb(10, 20, 60);
   
    
}
.sub-h-head{
  background-color: #07023b;
}
.nav-container a {
  white-space: nowrap; /* Prevents text from wrapping */
  display: inline-block; /* Ensures the link stays in one line */
}

.nav-container select {
  white-space: nowrap; /* Ensures the dropdown text doesn't wrap */
}

/* .mm{
  margin-left: 10px;
 
} */

  .link:hover{
    background-color: rgb(182, 179, 179);
  }

  .foot-container{
    background-color: rgb(10, 20, 60);
  }
  
  .sub-foot-one{
    padding-left: 40px;
    width: 220px;
  }
  .sub-foot{
    padding-left: 40px;
    
  }
  .des-footer{
    margin-top: -20px;
  }
  .foot-icons{
    font-size: 20px;
  }
  .banner-container{
    width: 100%;
  }
  .carousel-wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  
  .carousel-container {
    display: flex;
    transition: transform 0.5s ease-in-out; 
  }
  
  .carousel-image {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    object-fit: contain; /* Ensure the full image is visible */
  }
  
  .dots-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .dot {
    height: 10px;
    width: 10px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .dot.active {
    background-color: #333;
  }
  .top-tech {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 34px;
    font-stretch: extra-condensed;
    font-weight: 600;
    /* border-bottom: 2px solid transparent;
    border-image: linear-gradient(0.25turn, #10053a, #4856eb, #f9f9f9, #07023b); */
    border-image-slice: 1;
    text-align: center; 
    width: 100%;
    align-items: center;
   color: #07023b;
    border-radius: 10px;
    
    /* margin-left: 500px; */
    
}
.head-dis{
  display: flex;
 
}
.hor-dis{
  color: #2819d3;
  width: 200px;
}
@media only screen and (max-width: 1200px) {
  .top-tech {
      width: 50%;
      font-size: 28px;
      margin-left: 300px;
  }
}

@media only screen and (max-width: 768px) {
  .top-tech {
      width: 70%;
      font-size: 24px;
      margin-left: 50px;
  }
}

@media only screen and (max-width: 480px) {
  .top-tech {
      width: 90%;
      font-size: 20px;
      margin-left: 10px;
  }

}


.custom-card {
  max-width: 280px;
}
  
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
 
}
.header-abt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  /* border: 2px solid #f16101; */
}
.toggle{
  background-color: #07023b;
}
.left-header,
.right-header {
  flex: 1;
  text-align: center;
  font-size: 14px;
  color: #333;
}

.title {
  flex: 2;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
}

.main-content {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.image-gallery {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.side-image {
  width: 250px;
  height: 70%;
}

.main-image {
  width: 500px;
  height: auto;
}

.content {
 color: black;
  padding: 20px;
 font-weight: 400;

  font-size: 18px;
  text-align: justify;
}

p {
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
  }

  .main-content {
    flex-direction: column;
    align-items: center;
  }

  .side-image {
    width: 100%;
    display: none;
    margin-bottom: 10px;
  }
  
  .main-image {
    width: 100%;
  
    margin-bottom: 10px;
  }

  .title {
    font-size: 20px;
  }
}

/* 
.one{
  width: 180px;
}
.two{
  width: 250px;
  margin-top: -25px;
}
.three{
  margin-top: -10px;
  width: 200px;
  margin-left: 20px;
}
.four{
  margin-top: -40px;
}

.five{
  width: 200px;
  margin-top: -130px;
  margin-left: -20px;
}
.six{
  width: 250px;
  margin-top: -150px;
 
}
.seven{
  width: 160px;
  margin-top: -120px;
  margin-left: 50px;
} */

/* style.css */

.logo-main {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  background-color: rgb(216, 219, 219);
  margin-bottom: -50px;
}



@media screen and (max-width: 768px) {
  .container {
    grid-template-columns: repeat(2, 1fr);
  }
}
.accredated{
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-size: 30px;
font-stretch: extra-condensed;
font-weight: 600;
margin-bottom: 20px;
}


.cont-btn{
  background-color: rgb(10, 20, 60);
}

.cont-btn:hover{
  background-color: white;
  color: rgb(10, 20, 60);
  border: 2px solid rgb(10, 20, 60);
  font-weight: bold;
}
/*scroll*/
@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.animate-scroll {
  animation: scroll 20s linear infinite;
}

.two-logo{
  margin-top: -50px;
}


/* contactus */

.Contact-baner-container {
  margin-bottom: 0; 
  padding-bottom: 0;
}

.Contact-container {
  display: flex;
  justify-content: start;
  margin-left: 80px;
  align-items: center;
  padding: 5px 0; 
  min-height: 15vh; 
 
}

.button-container {
  display: flex;
  justify-content:space-between ;
  width: 60%;
  max-width: 1200px;
  
}

.button-container button {
  width: 280px;
  margin: 0 10px;
  padding: 5px;
  font-size: 18px;
  cursor: pointer;
  font-weight:300;

 
}

.button-container button:first-child {
  margin-left: 0;
}

.button-container button:last-child {
  margin-right: 0;
}


@media (max-width: 768px) {
  .button-container {
    width: 90%;
  }
  .button-container button {
   
    width: 100px; }
    .button-container {
      width:300px;
      overflow-x: scroll;
      margin-left: -50px;
      scrollbar-width: none; 
      -ms-overflow-style: none; 
    }
    
    .button-container::-webkit-scrollbar {
      display: none; 
    }
  
}

.btn-active{
  color: rgb(10, 20, 60);
  border-bottom: 3px solid rgb(10, 20, 60);
  font-weight:bold;
}

/* customer support */

.responsive-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color:#07023b;
}
.responsive-form-dem{
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 50px;
  background-color: white;
  border-radius: 8px;
  padding-bottom: 100px;
  padding-top: 100px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color:#07023b;
}
.view-main{
  /* background-color: #e3ecef; */
}

.responsive-form input:focus,
.responsive-form select:focus,
.responsive-form textarea:focus {
  background-color: #e8f0fe;
  outline: none;
}

.form-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 48%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
}

.form-group textarea {
  width: 100%;
  height: 80px;
  resize: none;
}

.captcha {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.top-tech-dem {
 
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 30px;
  font-stretch: extra-condensed;
  font-weight: 600;
  border-bottom: 3px solid transparent;
  border-image: linear-gradient(0.25turn, #10053a, #4856eb, #f9f9f9, #07023b);
  border-image-slice: 1;
  text-align: center; 
  
  align-items: center;

  /* margin-left: 500px; */
  
}
.dem-head{
  display: flex;
  justify-content: center;
  
}

.submit-btn {
  display: block;
  width: 30%;
 /* margin-left: 180px; */
  padding: 12px;
   background-color: white;
   color: #10053a;
  /* background-image: linear-gradient(to right, #ff416c, #ff4b2b); */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  margin-top: 20px;
}



@media (max-width: 768px) {
  .form-group {
    flex-direction: column;
  }

  .form-group input,
  .form-group select {
    width: 100%;
    margin-bottom: 10px;
  }
}
.description{
  text-align: center;
  color: white ;
  font-weight: 600;
}

.cert-img{
  width: 100%;
}
.cert-locker {
  display: flex;
}
.locker-img {
  width: 100px;
}
.cert-main {
  display: flex;
  flex-direction: column;
  margin-left: 90px;
  margin-right: 80px;
  margin-top: 80px;
  padding-left: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  border: 1px solid yellow;
}
.cert-head {
  font-size: 20px;
  font-weight: 600;
  color: rgb(10, 20, 60);
  padding-bottom: 10px;
}
.authenticate {
  margin-top: 10px;
  padding-left: 20px;
}
.verify-btn {
  padding-left: 120px;
  display: flex;
  gap: 30px;
}
.id-input {
  border: 1px solid rgb(207, 206, 206);
  width: 150px;
  border-radius: 5px;
}
.verify-button {
  border: 1px solid black;
  width: 100px;
  background-color: rgb(10, 20, 60); 
  color: white;
  padding-top: 1px;
  padding-bottom: 1px;
}


@media (max-width: 768px) {
  .cert-locker {
    flex-direction: column;
    align-items: center;
  }
  .locker-img {
    width: 80px;
    margin-bottom: 20px;
  }
  .cert-main {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 40px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .verify-btn {
    flex-direction: column;
    align-items: center;
    padding-left: 0;
    gap: 15px;
  }
  .id-input {
    width: 100%;
  }
  .verify-button {
    width: 100%;
    text-align: center;
  }
}
.sub-msg{
  color: white;
 margin-left: 80px;
 font-size: 20px;
 
}
.fex-vieweach{
  display: flex;
  
}
.fex-one {
  max-width: 900px;
  margin: 0 auto;
  padding: 10px; /* Add padding for spacing */
  box-sizing: border-box;
}
.div-over{
  background-color: #dce1ea;
  margin-left: 100px;
  margin-top: 30px;
  margin-right: 50px;
  padding-bottom: 30px;
  padding-top: 20px;
}
.div-overv{
  background-color: #dce1ea;
  margin-left: 100px;
  margin-top: 0px;
  margin-right: 50px;
  margin-bottom: 30px;
  padding-top: 20px;
 padding-bottom: -10px;
}
@media (max-width: 768px) {
  .sub-msg {
    margin-left: 60px; 
    font-size: 18px;
  }
  .fex-one{
    display: flex;
    flex-direction: column;
    min-width: 600px;
  }
  .fex-vieweach{
    display: flex;
    flex-direction: column;
  }
  .div-over{
    margin-left: -20px;
  }
  .div-overv{
    margin-left: -20px;
  }
  
}


@media (max-width: 480px) {
  .sub-msg {
    margin-left: 80px; 
    font-size: 16px; 
    text-align: center; 
  }
  .fex-one {
    display: flex;
    flex-direction: column;
    width: 90%; /* Slight padding for ultra-small screens */
    min-width: unset; /* Remove fixed minimum width */
  }

  .fex-vieweach{
    display: flex;
    flex-direction: column;
  }
  .div-over{
    width: 100%;
    margin-left: 8px;
  }
  .div-overv{
    width: 100%;
    margin-left: 8px;
  }
}
@media (max-width: 320px) {
  .fex-one {
    width: 100%; /* Full width for very small phones */
    padding: 5px; /* Reduce padding for small screens */
  }
}
.verify-msg{
  color: red;
  font-weight: bold;
  font-size: 20px;
}
.cert-msg-div{
  display: flex;
  gap: 30px;
}
@media (max-width: 480px) {
  .cert-msg-div{
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .cert-msg-div{
    display: flex;
    flex-direction: column;
  }
  .overview-main1{
    margin-left: -90px;
  }
}

/* eda */

.overview-main {
  font-size: 28px;
  font-weight: bold;
  margin-top: 50px;
  margin-bottom: 10px;
  text-align: left;
  margin-left: 120px;
  color: rgb(10, 20, 60);
}
.overview-main1 {
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 120px;
  color: rgb(10, 20, 60);
}
@media (max-width: 480px) {
  .overview-main1 {
    padding-left:   100px;
    font-size: 20px;
  }
}
@media (max-width: 480px) {
.sing-btn-div{
  margin-top: 40px;
}
}
.sing-btns{
  
  color: rgb(10, 20, 60);
  font-weight: 700;
  font-size: 15px;
  padding: 5px;
  border: 1px solid rgb(10, 20, 60);
  outline: none;
  border-radius: 10px;
}
.sing-btns:hover{
  color: white;
  background-color: rgb(10, 20, 60);
}
.sing-btn-div{
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-bottom: 50px;

}
.desc-career{
  margin-top: 30px;
}





.eda-desc {
  margin-left: 120px;
  margin-right: 110px;
  text-align: justify;
  font-size: 18px;
  margin-top: 20px;
  font-weight: bold;
}
.eda-desc-tech {
  margin-left: 120px;
  margin-right: 110px;
  text-align: justify;
  font-size: 18px;
  max-width: 600px;
  font-weight: 700;
}
@media screen and (max-width: 480px){
  .eda-desc-tech{
    min-width: 260px;
    margin-left: 20px;
    margin-top: 20px;
  } 
}

.eda-subhead {
  margin-left: 120px;
  font-size: 25px;
  font-weight: 700;
  color: rgb(10, 20, 60);
}
.li-eda{
  font-weight: 700;
}
.eda-ul {
  margin-left: 140px;
  margin-right: 110px;
  text-align: justify;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 100px;
}
.res-vieweach{
  margin-top: 230px;
  padding-top: 100px;
  padding-bottom: 100px;
  
}
.eda-form {
  padding-bottom: 150px;
 
}

@media screen and (max-width: 1024px) {
  .eda-desc,.eda-desc-view .eda-ul, .eda-subhead {
    margin-left: 60px;
    margin-right: 60px;
    font-size: 14px;
  }

  .overview-main {
    font-size: 26px;
  }

  .eda-subhead {
    font-size: 22px;
  }
}


@media screen and (max-width: 768px) {
  .eda-desc, .eda-ul, .eda-subhead {
    margin-left: 30px;
    margin-right: 30px;
    font-size: 16px;
  }
  .career-fex{
    display: flex;
    flex-direction: column;
    margin-top: -100px;
    width: 1200px;
    margin-left: -40px;
  }
  .responsive-form-dem{
    margin-top: -50px;
    width: 800px;
    margin-left: 140px;
  }
  .eda-desc-view{
    display: none;
  }
  .eda-desc-tech{
    text-align: left;
    text-align: justify;
   margin-left: 30px;
  }
  .overview-main {
    font-size: 24px;
    display: none;
  }

  .eda-subhead {
    font-size: 20px;
  }

  .eda-form {
    padding-bottom: 80px;
  }
  .res-vieweach{
    margin-top: 0px;
  }
}


@media screen and (max-width: 480px) {
  .eda-desc, .eda-ul, .eda-subhead {
    /* margin-left: 20px; */
    /* margin-right: 20px; */
    font-size: 18px;
    font-weight: 700;
    
  }
  ul.eda-desc{
    margin-left: 20px;
    
  }
  .square-fex{
    font-weight: 700;
  }
 .res-vieweach{
  margin-top: 0px;
 }

  .overview-main {
    font-size: 20px;
   display: none;
  }

  .eda-subhead {
    font-size: 18px;
  }

  .eda-form {
    padding-bottom: 50px;
  }
}


/* admin */
.sidebar{
  background-color: rgb(10, 20, 60);
  color: white;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 20px;
  margin-bottom: -110px;
  padding-bottom: 160px;
}
.admin-main{
  display: flex;
  margin-bottom: -135px;
}
.admlogo{
  width: 180px;
}
.adm-btn{
  color: white;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 3px;

}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

 
}

.login-card {
 
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 400px;
}

.logo {
  width: 120px;
 
  margin: 10px auto;
  border-radius: 50%;
}

.input-field {
  margin-bottom: 20px;
}

.input-field label {
  display: block;
  margin-bottom: 10px;
}

.input-field input {
  width: 100%;
  height: 40px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

button[type="submit"] {
  width: 100%;
  height: 40px;
 padding-bottom: 3px;
  color:#07023b;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.load{
  display: none;
}
button[type="submit"]:hover {
  background-color:#07023b;
  color: white;
  
}
@media (max-width: 768px) {
  .login-card {
    padding: 20px;
  }
}

@media (max-width: 480px) {
  
  .cardIcon{
    margin-left: 0px;
  }
  .cardIconone{
    margin-left: 20px;
  }
  .login-card {
    padding: 10px;
  }
  .logo {
    width: 50px;
    height: 50px;
  }
  .login-container{
    margin-top: -80px;
    
  }
}

.admin-view{
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: 600;
  columns: #0a143c;
}
.table-admin{
  margin:auto 80px;
}
.table-admin tr td {
  border: 1px solid rgb(10, 20, 60);
}
.table-admin tr th {
  border: 1px solid rgb(10, 20, 60);
}
.scrolldiv{
  overflow-y: scroll;
  height: 700px;

scrollbar-width: none; 
-ms-overflow-style: none;  
}


.scrolldiv::-webkit-scrollbar {
display: none;
}

.mess-feild{
  max-width: 300px;
}

.table-admin th, .table-admin td {
  padding: 10px;
  text-align: left;
}


.table-admin th.message-col, .table-admin td.message-col {
  width: 400px; 
  word-wrap: break-word; 
}

.table-admin th.message-col, .table-admin td.message-cols {
  width: 400px; 
  word-wrap: break-word; 
}
.table-admin th.mes-len, .table-admin td.mes-len{
  width: 100px; 
  word-wrap: break-word; 
}
.table-admin th.message-col, .table-admin td.exp{
  width: 50px; 
  
}
.table-admin th.message-col, .table-admin td.pos{
  width: 30px; 
 
}
.table-admin th.message, .table-admin td.message{
  width: 50px; 
 
}


.eda-form-div{
  display: flex;
  margin-top: 50px;
  gap: 50px;
}
.eda-form-divv{
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  justify-content: center;
}

@media (max-width: 768px) {
  .eda-form-div {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
}

@media (max-width: 480px) {
  .eda-form-div {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
}


.Trend-main{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  /* margin-left: 100px;
  margin-right: 100px; */
  
}
.Trend-main-single{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  /* margin-left: 100px;
  margin-right: 100px; */
  
}
.cert-info{
  color: green;
  
  font-size: medium;
  font-weight: 800;
 
}

.btn-add{
  background-color: green;
  margin-top: 20px;
  padding: 5px;
 
  border-radius: 10px;
  color: white;

}
.ind-btn{
  display:flex;
  justify-content: end;
  margin-right: 80px;
  margin-bottom: 30px;
}
.new-course{
  display: flex;
  margin-left: 1000px;
  
}
.viewlist-main{
  max-width: 1200px;
  padding-bottom: 100px;
}
.title-viewcourse{
  text-align: center;
  font-size: 30px;
  color: rgb(10, 20, 60);
  font-weight: bold;
}
.view-image{
  margin-top: 20px;
  margin-left: 500px;
}
.delete-button{
  background-color: red;
  padding: 5px;
  color: white;
  margin-left: 500px;
}
.edt-del{
  display: flex;
  gap: 0px;
}
.edit-button{
  background-color: green;
  padding: 5px;
  width: 50px;
  color: white;
  margin-left: 20px;
}
.demand-button{
  background-color: rgb(10, 20, 60);
  padding: 5px;
  width: 130px;
  color: white;
  margin-left: 20px;
}
.demand-list{
  color: rgb(10, 20, 60); 
  margin-left: 100px;
  font-weight: bold;
}
.tech-main{
  display: grid;
  place-items: center;
}

/* CSS for Loading Animation */
.loader {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px; /* Size of the spinner */
  height: 50px; /* Size of the spinner */
  animation: spin 1s linear infinite; /* Animation */
  margin: 0 auto; /* Center it */
  margin-top: 200px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}




.why-container {
  font-family: 'Arial, sans-serif';
  background-color: #f1f3f4;
  padding-bottom: 0px;
 
  margin-top: 50px;
  margin-bottom: -130px;
  width: 100%;
  /* max-width: 1200px;  */
  margin-left: auto;
  margin-right: auto;
  
}

.header {
  padding: 20px;
  background-color: #eef2f4;
}

.headerText {
  font-size: 2vw; 
  font-weight: bold;
  color: rgb(120, 144, 240);
  margin-left: auto; 
  margin-right: auto; 
  text-align: center; 
}


@media (max-width: 600px) {
  .headerText {
    font-size: 36px; 
    margin-left: 0;
  }
}


.imageSection {
  position: relative;
  text-align: center;
}

.backgroundImage {
  width: 100%;
  height: auto;
}

.overlayText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #333;
  font-size: 28px;
  font-weight: bold;
  background-color: rgba(231, 232, 234, 0.7); 
  padding: 10px 20px;
  border-radius: 10px;
}

.overlayHeading {
  margin: 0;
  color: #130b6f;
  font-weight: 700;
  font-size: 30px;
}
.top-section{
  display: flex;

}
.top-hr{
  width: 100px;
 
  border: 2px solid #0a143c;
  margin-top: 80px;
}
.cardSection {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px 10px;
}

.card {
  text-align: center;
  background-color: #fff;
  padding: 10px;
  margin: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  flex: 1;
  max-width: 150px;
  /* border: 2px solid #f16101; */
}


.icon {
  font-size: 32px;
  color: #e76f51;
  margin-top: 12px;
  width: 130px;
  margin-left: 0px;
}

.cardTitle {
  font-size: 18px;
  margin: 10px 0;
  margin-top: -8px;
  font-weight: 600;
}

.cardDescription {
  font-size: 15px;
  color: #333;
}

.placement{
  margin-top: 10px;
  width: 90px;
  margin-left: 70px;
}
.placement-desc{
  margin-top: 5px;
}
.project{
  margin-top: 10px;
  width: 100px;
  margin-left: 60px;
}
.pro-des{
  margin-top: -5px;
}
@media (max-width: 768px){
  .placement{
   
    margin-left: 45px;
  }
  .project{
    margin-left: 40px;
  }
  .cardSection{
    display: flex;
    flex-direction: column;
    margin-left: 50px;
  }
 .study{
  margin-left: 0px;
 
 }
.cardSection{
  display: flex;
  flex-direction: row;
  margin-left: 0px;
}
}

/* subbnaaer */
/* Default styles for larger screens */
.sub-ban-head {
  font-weight: 600;
  font-size: 28px;
  color:#07023b;
}
.sub-ban-head:hover{
  color: rgb(8, 30, 141);
}
.sub-ban-headone:hover{
  color: rgb(8, 30, 141);
}
.sub-ban-headtwo:hover{
  color: rgb(8, 30, 141);
}
.sub-ban-headone {
  font-weight: 600;
  font-size: 28px;
  color: #07023b;
}
.sub-ban-headtwo {
  font-weight: 600;
  font-size: 28px;
  color: #07023b;
}


.desc-subban {
  color: rgb(34, 33, 33);
  font-size: 18px;
  border-left: 4px solid #07023b;
  padding-left: 5px;
 
}

.sub-banner {
  display: flex;
  align-items: center;
  /* margin-left: 100px;
  margin-right: 100px; */
  background-color: #f0eded;
  margin-top: 20px;
}

.bannimg {
  width: 100%;
}

/* Responsive styles for tablets (768px and up) */
@media screen and (max-width: 1024px) {
  .sub-banner {
    flex-direction: column; /* Stack content vertically */
    margin-left: 50px;
    margin-right: 50px;
  }

  .sub-ban-head {
    font-size: 24px; /* Slightly smaller font size */
    text-align: center;
  }
  .sub-ban-headone {
    font-size: 24px; /* Slightly smaller font size */
    text-align: center;
  }
  .sub-ban-headtwo {
    font-size: 24px; /* Slightly smaller font size */
    text-align: center;
  }

  .desc-subban {
    font-size: 16px; /* Adjusted for readability */
    text-align: justify;
  }
}

/* Responsive styles for mobile devices (480px and up) */
@media screen and (max-width: 768px) {
  .sub-banner {
    margin-left: 20px;
    margin-right: 20px;
  }

  .sub-ban-head {
    font-size: 20px;
    margin-left: -100px
   
  }
  .sub-ban-headone {
    font-size: 20px;
    margin-left: -100px;
    width: 400px;
   
  }
  .sub-ban-headtwo {
    font-size: 20px;
    margin-left: -100px
   
  }

  .desc-subban {
    font-size: 15px;
    border-left: 3px solid #07023b; /* Smaller border */
   width: 400px;
   text-align: justify;
    margin-left: -80px
  }
}

/* Extra-small devices (phones, less than 480px) */
@media screen and (max-width: 480px) {
  .sub-banner {
    flex-direction: column;
    margin-left: -100px;
    margin-right: 10px;
  }
.career-fex{
  display: flex;
  flex-direction: column;
  width: 780px;
  margin-left: -120px;
  margin-top: -120px;
}
.responsive-form-dem{
  width: 400px;
  margin-left: 125px;
  margin-top: -20px;
}
.eda-desc-view{
 display: none;
}
  .sub-ban-head {
    font-size: 18px;
    width: 400px;
   margin-left: -100px;
  }
  .sub-ban-headone{
    margin-left: -50px;
    font-size: 18px;
    width: 400px;
  }
  .sub-ban-headtwo{
    margin-left: -100px;
    font-size: 18px;
    width: 400px;
  }

  .desc-subban {
    font-size: 14px;
    border-left: 2px solid #07023b; /* Smaller border */
   width: 300px;
   text-align: justify;
   margin-left: 0px;
  }

  .bannimg {
    width: 100%; /* Reduce image size on small screens */
    margin: 0 auto; /* Center the image */
  }
  .card-description {
    width: 300px;
   margin-top: 50px;
  text-align: justify;
 

  }
 
}
.cards-row-container {
  display: flex;
  
  justify-content: center;
  gap: 20px; /* Space between the cards */
 margin-top: 50px;
}

.small-card {
  width: 30%; /* Make each card take up a smaller, equal width */
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  border: 2px solid rgb(7, 2, 59);
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
 
}
.small-card-two {
  width: 30%; /* Make each card take up a smaller, equal width */
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  border: 2px solid rgb(7, 2, 59);
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

}
.small-card-course {
  width: 30%; /* Make each card take up a smaller, equal width */
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin-top: 20px;
  border: 2px solid rgb(7, 2, 59);
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
 
}
.sing-cont{
  margin-left: 100px;
  margin-right: 100px;
}
.overview-main1 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  
}

.eda-desc {
 
  color: black;
}
.eda-desc-view {
 background-color: rgb(235, 232, 232);
  color: black;
  margin-left: 120px;
  margin-right: 110px;
  text-align: justify;
  font-size: 18px;
  margin-top: 20px;
  padding: 15px;
  font-weight: bold;
}
.info-cards-container {
  display: flex;
  flex-wrap: wrap; /* Allow cards to wrap to the next line on smaller screens */
  justify-content: space-between;
  gap: 20px; /* Space between the cards */
  margin-top: 50px;
  margin-left: 130px;
  margin-right: 100px;
}
.scope-card{
  background-color: #1b1f42;
  
}

.info-card {
  flex: 1 1 100%; /* Default to full width for smaller screens */
  max-width: 100%; /* Ensure it doesn't exceed the screen width */
 
  border-radius: 8px;
  padding: 15px;
 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.objective-card{
  background-color: #cadce9;
  color: white;
}

/* Media query for medium-sized screens (tablets) */
@media (max-width: 768px) {
  .info-cards-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: 0px;
  }

  .info-card {
    width: 100%; /* Make the card take most of the width on smaller screens */
  }
}


/* Media query for large-sized screens (desktops) */
@media (min-width: 992px) {
  .info-card {
    flex: 1 1 30%; /* Each card takes up roughly a third of the width on desktops */
  }
}

.career-description,
.enroll-now {
 font-size: 20px;
 text-align:center;
  margin-top: 80px;
  color: black;
 
}
.enroll-now{
 border: 2px solid white;
  border-radius: 25px;
  padding: 15px;
 text-align: center;
  box-shadow: 10px 10px 5px -4px rgba(181,170,181,1);
}

.career-description-fex
 {
  margin-left: 130px;
  margin-right: 10px;
  margin-top: 150px;
  color:#07023b;
  width: 50%;
  font-size: 18px;
  font-weight: bold;
  text-align: justify;
  background-color:#cadce9;
  border-radius: 25px;
  padding: 15px;
  box-shadow: 10px 10px 5px -4px rgba(181,170,181,1);
}
.card-description-enroll{
  font-weight: bold;
}
.career-fex{
  display: flex;
  gap: 50px;
}
/* Margin adjustments for larger screens */
@media (min-width: 992px) {
  .career-description,
  .enroll-now {
    margin-left: 100px;
    margin-right: 100px;
  }
}

.card-title {
  font-size: 20px;
  margin-bottom: 10px;
  color:white;
  font-weight: 600;
}
.card-title-career {
  font-size: 20px;
  margin-bottom: 10px;
  color:rgb(32, 47, 76);
  font-weight: 600;
}
.card-title-course {
  font-size: 20px;
  margin-bottom: 10px;
  color:rgb(32, 47, 76);
  font-weight: 600;
}
.card-description {
  color: white;
  margin-left: 30px;
}
.card-description-obj{
  color: rgb(32, 47, 76);
  margin-left: 30px;
}
.card-description-obj {
  color: rgb(32, 47, 76);
  margin-left: 30px;
  font-size: 16px;
  font-weight: 700;
}
.carddd{
  display: flex;
  flex-direction: column;
  gap: 0px;
 padding-top: 30px;
}

/* popup */
/* Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
  text-align: center;
}

.modal-content button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-content button:hover {
  background-color: #45a049;
}
.cc{
  margin: 0 ;
 
  padding: 0 ;
}

/* scholorship page */
/* Main container styling */

.main-container {
  display: flex;
 
  margin-left: 110px;
  margin-right: 110px;
  margin-top: 50px;
  gap: 16px; /* Space between the two divs */
}

/* Styling for equal divs */
.equal-div {
  flex: 1; /* Makes both divs take equal width */

  padding: 20px;
  text-align: center;
  color: #07023b;
  border-radius: 8px;
}
.pres-img{
  width: 100%;
 
}
.equal-div1 {
  flex: 1; /* Makes both divs take equal width */

  padding: 20px;
  text-align: center;
  color: #07023b;
  border-radius: 8px;
}
.sc-img{
  display: flex;
  justify-content: center;
}
/* Responsive styling */
@media (max-width: 768px) { /* For smaller screens */
  .main-container {
    flex-direction: column; /* Stack divs vertically */
  }
  .equal-div1 {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-left: 30px;
  }
  
  .pres-img {
    min-width: 350px;
    max-width: 100%; /* Ensure the image doesn't exceed container's width */
    display: block; /* Make image a block element */
    margin: 0 auto; /* Center the image horizontally */
  }
  .main-container {
    margin-left: 50px;
    
  }
}
@media (max-width: 480px) { /* For smaller screens */
  .main-container {
    flex-direction: column; /* Stack divs vertically */
    margin-left: 0; /* Adjust margin to remove left spacing */
  }
.career-description-fex{
  max-width: 380px;
}
  .equal-div1 {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-left: 30px;
  }
  
  .pres-img {
    min-width: 350px;
    max-width: 100%; /* Ensure the image doesn't exceed container's width */
    display: block; /* Make image a block element */
    margin: 0 auto; /* Center the image horizontally */
  }
  .main-container {
    margin-left: 50px;
    
  }
  
}
/* Container styling */
.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh; /* Full viewport height for centering */
  padding: 20px; /* Ensure spacing for smaller screens */
  box-sizing: border-box;
}

/* Form card styling */
.form-card {
  background: #ffffff;
  padding: 20px; /* Reduce padding for smaller screens */
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%; /* Allow the form to stretch within the container */
  max-width: 570px; /* Limit the maximum width for larger screens */
  text-align: center;
  box-sizing: border-box;
}

/* Form title styling */
.form-title {
  margin-bottom: 20px;
  font-size: 1.5rem; /* Use responsive font size */
  font-weight: 600;
  color: #07023b;
 
  border-bottom: 2px solid #9face6;
  display: inline-block;
  padding-bottom: 5px;
}

/* Form group styling */
.form-group {
  margin-bottom: 15px;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: white;
}
.responsive-form1{
  /* min-width: 500px; */
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color:#07023b;
}
.form-group input,
.form-group select {
  width: 80%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.form-group input:focus,
.form-group select:focus {
  border-color: #9face6;
  outline: none;
}

/* Submit button styling */
.submit-button {
  width: 100%;
  padding: 12px;
  font-size: 1rem; /* Use scalable font size */
  font-weight: bold;
  color: #fff;
  background: linear-gradient(to right, #74ebd5, #9face6);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.submit-button:hover {
  background: linear-gradient(to right, #9face6, #74ebd5);
}

/* Responsive styling using media queries */
@media (max-width: 768px) {
  .form-card {
    padding: 15px; /* Adjust padding for tablets and smaller screens */
    width: 100%; /* Allow the form to stretch within the container */
  min-width: 500px;
  }
  .form-group select {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  .form-title {
    font-size: 1.25rem; /* Scale down title font size */
  }
}

@media (max-width: 480px) {
  .form-card {
    padding: 10px;
    width: 100%; /* Allow the form to stretch within the container */
  min-width: 400px;
  margin-left: 60px;
  
   /* Further reduce padding for mobile screens */
  }

  .form-title {
    font-size: 1.1rem; /* Scale down title font size for smaller screens */
  }
  .form-group input,
.form-group select {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}
}

.skill-capt{
  display: flex;
  justify-content: center;
  margin-left: 20px;

}
.scholor-img{
  display: flex;
  justify-content: center;
}


/* Modal overlay */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  animation: fadeIn 0.5s forwards; /* Fade-in animation */
}

/* Bounce animation for modal */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Modal content box with a bounce effect */
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transform: scale(0.9); /* Initial scale */
  animation: bounce 0.6s ease-out forwards; /* Bounce effect */
}

/* Bounce keyframes */
@keyframes bounce {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* Close button style */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s ease;
}

/* Hover effect on close button */
.close-btn:hover {
  transform: scale(1.2);
}
.cert-hh{
  font-size:25px;
  color: #07023b;
  font-weight: 800;
  margin-bottom: 20px;
}
.cert-det{
  font-size: 18px;
}

.page-not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: white;
  color: #ffffff;
  text-align: center;

}

.content-container {
  max-width: 600px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.content-container h1 {
  font-size: 6rem;
  margin: 0;
  font-weight: bold;
}

.content-container p {
  font-size: 1.5rem;
  margin: 20px 0;
}

.home-link {
  display: inline-block;
  padding: 12px 24px;
  font-size: 1.2rem;
  color: #ffffff;
  background-color:#07023b;
  border: none;
  border-radius: 6px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.home-link:hover {
  background-color:#2819d3;
}

@media (max-width: 768px) {
  .content-container h1 {
    font-size: 4rem;
  }

  .content-container p {
    font-size: 1.2rem;
  }

  .home-link {
    font-size: 1rem;
    padding: 10px 20px;
  }
}

.main-bruch {
  display: flex;
  justify-content: center;
  align-items: center;

 margin: 100px;
}

.upload-button {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color:#07023b; /* Button color */
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s;
  gap: 8px;
}

.upload-button:hover {
  background-color: #0056b3; /* Hover effect */
}

.upload-logo {
  width: 24px;
  height: 24px;
}

.file-input {
  display: none; /* Hide the file input element */
}
.mesc-head {
  text-align: center;
  font-size: 2.5rem; /* Scales better across devices */
  color: #07023b;
  font-weight: 750;
  /* Relative unit for spacing */
}

.mesc-para {
  text-align: justify;
  margin: 1.25rem auto; /* Center the paragraph with auto margins */
  font-size: 1rem; /* Scalable text size */
  color: gray;
  max-width: 90%; /* Limit width for larger screens */
}

/* Media Queries for smaller screen adjustments */
@media (max-width: 768px) {
  .mesc-head {
    font-size: 1.5rem; /* Reduce heading size for tablets */
    margin-top: 2rem;
  }
  
  .mesc-para {
    font-size: 0.9rem; /* Slightly smaller text for tablets */
    max-width: 95%; /* Reduce margins for smaller screens */
  }
}

@media (max-width: 480px) {
  .mesc-head {
    font-size: 1.25rem; /* Further reduce heading size for phones */
    margin-top: 1.5rem;
  }
 
  .mesc-para {
    font-size: 1.2rem; /* Smaller text for phones */
    max-width: 100%; /* Use full width on very small screens */
    margin-left: 10px;
    margin-right: 10px;
  }
}
.sub-mesc {
  background-color: rgb(228, 93, 8);
  color: white;
  padding: 3rem; /* Use relative units */
  margin-top: 1.25rem; /* Relative margin for better scaling */
  font-size: 1rem; /* Scalable font size */
}

.sub-mesc-head {
  text-align: center;
  font-size: 2rem; /* Slightly reduced for better scaling */
  color: white;
  font-weight: 750;
}

.sub-mesc-ul {
  list-style: square;
  margin-top: 2rem; /* Adjusted for better readability */
  line-height: 1.5rem; /* Better spacing */
  padding: 0 1rem; /* Add padding for smaller screens */
  font-size: 1rem;
  text-align: justify;
}

.sub-mesc-ul li {
  display: flex;
  justify-content: center;
  text-align: center; /* Added for better alignment */
}

.mesc-head-third {
  text-align: center;
  font-size: 2.3rem; /* Scales better across devices */
  color: #07023b;
  font-weight: 750;
  margin-top: 3rem; /* Relative unit for spacing */
  margin-bottom: 1.25rem;
}

.mesc-third-one{
  text-align: justify;
  margin: 1rem auto; /* Center the paragraph with auto margins */
  font-size: 1rem; /* Scalable text size */
  color: gray;
  max-width: 90%;
  text-align: justify;
}
.mesc-third-on{
  text-align: justify;
  margin: 1rem auto; /* Center the paragraph with auto margins */
  font-size: 1rem; /* Scalable text size */
  color: gray;
  max-width: 90%;
  text-align:center;
}

.mesc-third-two{
  text-align: justify;
  margin: 1.25rem auto; /* Center the paragraph with auto margins */
  font-size: 1rem; /* Scalable text size */
  color: gray;
  max-width: 90%;
  text-align: center;
}
.mesc-third-one li{
  list-style-type:disc;
}

.sel-head{
  background-color: #07023b;
}

.ind-head{
  font-size: 30px;
  color: #07023b;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px;
}
.sub-indi {
  
}

.ind-para {
  text-align: justify;
  font-size: 18px;
  color: rgb(57, 55, 55);
}

/* General Styles for .responsive-ind-form */
.responsive-ind-form {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #07023b;
  margin-top: 100px;
  /* Ensure it takes full width on smaller screens */
  width: 100%;
}

/* Base Styles for .ind-ff */
.ind-ff {
  display: flex;
  margin-left: 100px;
  margin-right: 100px;
  gap: 50px;
}

/* Media Query for 1200px and below */
@media screen and (max-width: 1200px) {
  .ind-ff {
    display: flex;
    justify-content: space-between; /* Adjust spacing for wider screens */
  }

  .ind-para {
    font-size: 18px;
  }

  .responsive-ind-form {
    max-width: 90%; /* Ensure form does not take up too much space */
  }
}

/* Media Query for 768px and below */
@media screen and (max-width: 768px) {
  .ind-ff {
    display: flex;
    flex-direction: column;
    gap: 30px; /* Reduce gap between elements */
    margin-left: 20px;
    margin-right: 20px;
  }

  .ind-para {
    font-size: 16px;
  }

  .responsive-ind-form {
    max-width: 90%;
    margin-top: 60px; /* Adjust form's top margin for better spacing */
  }
}

/* Media Query for 480px and below */
@media screen and (max-width: 480px) {
  .ind-ff {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Further reduce gap between elements */
    margin-left: 10px;
    margin-right: 10px;
  }

  .ind-para {
    font-size: 16px;
  }

  .responsive-ind-form {
    max-width: 90%;
    margin-top: 40px;
    padding: 15px; /* Reduce padding for smaller screens */
  }
}

.course-mm {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  padding-bottom: 50px;
  padding-top: 0px;
}

.course-ssub {
  display: flex;
  gap: 30px;
  flex-wrap: wrap; /* Allow items to wrap */
  justify-content: center; /* Center align items on smaller screens */
}

.imgg-course {
  width: 200px;
  max-width: 100%; /* Ensure images are responsive */
}

.course-bor {
  border: 2px solid rgb(219, 215, 215);
  padding: 10px; /* Add padding for better spacing */
  border-radius: 10px; /* Optional: rounded corners for better aesthetics */
}

/* Responsive Design for small screens */
@media (max-width: 768px) {
  .course-mm {
    padding-top: 30px;
    padding-bottom: 30px;
    gap: 20px; /* Reduce gap for smaller screens */
  }

  .course-ssub {
    flex-direction: column; /* Stack items vertically */
    gap: 20px;
  }

  .imgg-course {
    width: 170px; /* Reduce image size for smaller screens */
  }
}

/* Extra small screens */
@media (max-width: 480px) {
  .course-mm {
    padding-top: 20px;
    padding-bottom: 20px;
    gap: 15px; /* Further reduce the gap */
  }

  .course-ssub {
    gap: 15px;
  }

  .imgg-course {
    width: 180px; /* Further reduce image size */
  }
}


/* franchiseee */
/* Default Styles (for large screens) */
.fran-prim {
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 30px;
  padding-top: 10px;
  
}
.fran-main {
  font-size: 20px;
  color: #0056b3;
  font-weight: 700;
  margin-top: 20px;
}
.fran-sub {
  font-size: 45px;
  color: #07023b;
  font-weight: 700;
}
.fran-para {
  font-size: 18px;
  text-align: justify;
}

/* Medium Screens (Tablets, small laptops) */
@media (max-width: 1024px) {
  .fran-prim {
    padding-left: 50px;
    padding-right: 50px;
  }
  .fran-main {
    font-size: 16px;
  }
  .fran-sub {
    font-size: 35px;
  }
  .fran-para {
    font-size: 18px;
  }
}

/* Small Screens (Mobile devices) */
@media (max-width: 768px) {
  .fran-prim {
    padding-left: 20px;
    padding-right: 20px;
  }
  .fran-main {
    font-size: 14px;
  }
  .fran-sub {
    font-size: 28px;
  }
  .fran-para {
    font-size: 18px;
  }
}

/* Extra Small Screens (Small mobile devices) */
@media (max-width: 480px) {
  .fran-prim {
    padding-left: 10px;
    padding-right: 10px;
  }
  .fran-main {
    font-size: 12px;
  }
  .fran-sub {
    font-size: 24px;
  }
  .fran-para {
    font-size: 18px;
  }
}


.fran-rev {
  font-size: 30px;
  text-align: center;
  color: orangered;
  font-weight: 600;
  padding-top: 50px;
}

.fran-exp {
  font-size: 25px;
  text-align: center;
  font-weight: 700;
  color: white;
  padding-bottom: 10px;
}

.fran-ff {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Align items vertically */
  align-items: center;    /* Align items horizontally */
  margin: 0 auto;         /* Center the container */
  width: 100%;            /* Full width for responsiveness */
  max-width: 1200px;      /* Limit maximum width */
  padding: 50px 1rem;     /* Add padding for spacing */
  box-sizing: border-box;
  gap: 30px;
}

.fran-inp {
  border: 1px solid rgb(236, 234, 234);
  padding: 5px 10px; /* Adjust padding for input fields */
  color: gray;
  width: 100%; /* Make inputs fill available width */
}
#fr-form{
  border-top: 5px dotted white;
  margin-top: 10px;
}
.fran-sub-ff {
  display: flex;
  gap: 20px;
  flex-wrap: wrap; /* Ensure items wrap on smaller screens */
}

input:focus, select:focus {
  outline: none;
  border: 1px solid rgb(236, 234, 234);
  box-shadow: none;
}

.fran-btn {
  background-color: orangered;
  color: white;
  width: 100%; /* Full width button for small screens */
  max-width: 200px; /* Limit button width */
  height: 40px; /* Adjust button height */
  border: 1px solid orangered;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px; /* Add rounded corners */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .fran-rev {
    font-size: 24px; /* Smaller font size for titles */
  }

  .fran-exp {
    font-size: 20px;
  }

  .fran-ff {
    padding: 30px 1rem; /* Reduce padding for smaller screens */
    gap: 20px;
  }

  .fran-inp {
    font-size: 16px; /* Adjust input font size */
  }

  .fran-sub-ff {
    flex-direction: column; /* Stack fields vertically */
    gap: 15px;
  }
}

@media (max-width: 480px) {
  .fran-rev {
    font-size: 18px; /* Further reduce font size */
  }

  .fran-exp {
    font-size: 16px;
  }

  .fran-ff {
    padding: 20px 1rem; /* Compact padding */
    gap: 15px;
  }

  .fran-btn {
    height: 35px; /* Adjust button height */
    font-size: 14px; /* Smaller button text */
   
  }
}


/* For .fran-features, apply a flex layout with wrapping on smaller screens */
.fran-features {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Centers the content */
  gap: 20px; /* Adds spacing between flex items */
}

/* For .fran-emb, style the text and make it responsive */
.fran-emb {
  font-size: 30px;
  text-align: center;
  color: #07023b;
  font-weight: 600;
  padding-bottom: 30px;
  padding-top: 50px;
  /* Apply responsiveness: */
  /* Default font size */
  font-size: 30px;
}

/* Media Queries for responsiveness */
@media screen and (max-width: 1200px) {
  .fran-emb {
    font-size: 26px; /* Slightly smaller font size on medium screens */
    padding-top: 60px; /* Adjust padding on medium screens */
  }
}

@media screen and (max-width: 768px) {
  .fran-emb {
    font-size: 22px; /* Even smaller font size on small screens */
    padding-top: 40px; /* Adjust padding for small screens */
  }
}

@media screen and (max-width: 480px) {
  .fran-emb {
    font-size: 18px; /* Font size for very small screens (mobile) */
    padding-top: 20px; /* Reduced padding for mobile */
  }

  .fran-features {
    flex-direction: column; /* Stack the features vertically on small screens */
  }
}
.fran-fact-desc{
  text-align: justify;
}

.fran-part {
  font-size: 30px;
  text-align: center;
  color: orangered;
  font-weight: 600;
  padding-bottom: 30px;
  padding-top: 80px;
  /* Apply responsiveness: */
  font-size: 30px;
}

.part-ff {
  display: flex !important;
  justify-content: center;
  gap: 50px;
  flex-wrap: wrap; /* Ensure items wrap on smaller screens */
}

.fra-fact-one,
.fra-fact-onee {
  background-color: #07023b;
  color: white;
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
  width: 350px;
  margin: 10px;
}

.fra-fact-onee {
  width: 450px;
}

.fra-li {
  border: 1px solid gray;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  list-style-type: none; /* Remove default bullets */
  position: relative;
}

.fra-two {
  padding-bottom: 40px;
  padding-top: 20px;
  padding-left: 50px;
}

.fra-sec-head {
  color: #07023b;
  font-size: 25px;
  font-weight: 800;
}

.fra-sec-ul::before {
  list-style-type: none;
}

.fra-sec-li {
  list-style-type: disc;
  padding-top: 20px;
  font-size: 18px;
}

.fran-skill {
  text-align: center;
  color: orangered;
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 40px;
}

.fran-eemb{
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 50px;
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(242,245,245,1) 0%, rgba(15,29,238,1) 0%, rgba(192,193,243,1) 0%, rgba(201,15,165,1) 0%, rgba(255,255,255,1) 0%, rgba(250,240,250,1) 100%, rgba(0,27,255,1) 100%, rgba(248,248,248,1) 100%, rgba(230,230,239,1) 100%, rgba(229,230,239,1) 100%, rgba(254,253,254,1) 100%, rgba(237,235,240,1) 100%, rgba(223,217,219,1) 100%, rgba(53,72,209,1) 100%, rgba(251,252,252,1) 100%, rgba(220,220,227,1) 100%, rgba(210,236,239,1) 100%, rgba(164,216,243,1) 100%, rgba(196,200,202,1) 100%, rgba(142,209,243,1) 100%, rgba(185,226,234,1) 100%);
}
.fran-hol {
  font-size: 40px;
  text-align: center;
  color: #07023b;
  font-weight: 600;
  padding-bottom: 30px;
  padding-top: 50px;
  font-size: 30px;
  
}
.fran-hhol{
  padding-bottom: 50px;
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(242,245,245,1) 0%, rgba(15,29,238,1) 0%, rgba(192,193,243,1) 0%, rgba(201,15,165,1) 0%, rgba(255,255,255,1) 0%, rgba(250,240,250,1) 100%, rgba(0,27,255,1) 100%, rgba(248,248,248,1) 100%, rgba(230,230,239,1) 100%, rgba(229,230,239,1) 100%, rgba(254,253,254,1) 100%, rgba(237,235,240,1) 100%, rgba(223,217,219,1) 100%, rgba(53,72,209,1) 100%, rgba(251,252,252,1) 100%, rgba(220,220,227,1) 100%, rgba(210,236,239,1) 100%, rgba(164,216,243,1) 100%, rgba(196,200,202,1) 100%, rgba(142,209,243,1) 100%, rgba(185,226,234,1) 100%);
}
/* Responsive design */
@media screen and (max-width: 1200px) {
  .fra-fact-one,
  .fra-fact-onee {
    width: 45%; /* Make them smaller on medium screens */
  }

  .fra-sec-head {
    font-size: 22px; /* Reduce font size */
  }

  .fra-li {
    padding-left: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .fran-part,
  .fran-hol {
    font-size: 28px;
  }

  .fra-two {
    padding-left: 30px;
  }
}

@media screen and (max-width: 992px) {
  .fra-fact-one,
  .fra-fact-onee {
    width: 100%; /* Make the items full width on smaller screens */
    margin: 10px 0; /* Adjust margins */
  }

  .fra-sec-head {
    font-size: 20px;
  }

  .fra-li {
    padding-left: 6px;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .fran-part,
  .fran-hol {
    font-size: 26px;
  }

  .fra-two {
    padding-left: 20px;
  }

  .part-ff {
    flex-direction: column;
    gap: 20px; /* Reduce the gap */
  }
}

@media screen and (max-width: 768px) {
  .fra-fact-one,
  .fra-fact-onee {
    width: 100%; /* Make the items full width */
  }

  .fra-sec-head {
    font-size: 18px;
  }

  .fra-li {
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .fran-part,
  .fran-hol {
    font-size: 24px;
  }

  .fra-two {
    padding-left: 10px;
  }

  .fra-sec-li {
    font-size: 16px; /* Adjust font size for list items */
  }
  .fran-eemb{
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 480px) {
  .fra-fact-one,
  .fra-fact-onee {
    width: 100%; /* Full width on very small screens */
  }

  .fra-sec-head {
    font-size: 16px;
  }

  .fra-li {
    padding-left: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .fran-part,
  .fran-hol {
    font-size: 22px;
  }

  .fra-two {
    padding-left: 5px;
  }

  .fra-sec-li {
    font-size: 14px; /* Smaller font size for very small screens */
  }
  .fran-eemb{
    padding-left: 10px;
    padding-right: 10px;
  }
}

.ready-main{
  text-align: center;
  margin-top: 30px;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color:rgb(10, 20, 60);
  
}
.ready-sub{
  display: flex;
  justify-content: center;
  gap: 10px;
}
.ready-head{
  font-size: 30px;
  color: white;
  font-weight: 600;
  padding-bottom: 20px;
}
.ready-para{
  color: white;
  font-size: 25px;
  font-weight: 600;
}
.ready-btn{
  background-color:rgb(247, 0, 0);
  font-weight: 600;
  color: white;
  font-size: 20px;
  padding-left: 20px;
  padding-right: 20px;
 
  height: 50px;
}
.fran-tri{
  background-color: rgb(10, 20, 60);
}